/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Flex, Box, Text } from '@theme-ui/components'
import SEO from '../components/seo'
import Header from '../components/header'
import RecentPost from '../components/recent-post'
import '../components/layout.css'
import EmailSubscribeForm from '../components/email-subscribe-form'
import Footer from '../components/footer'
import { Link, graphql } from 'gatsby'

const RecentPosts = ({ posts, placeholder }) => {
  return (
    <Flex sx={{ flexDirection: 'row', m: -2, flexWrap: 'wrap' }}>
      {posts.map((post, index) => {
        const oneThird = '33.33333%'
        return (
          <Box key={`${post.title}${index}`} sx={{ width: ['100%', oneThird, oneThird], p: 2 }}>
            <RecentPost sxx={{}} {...post} placeholder={placeholder}></RecentPost>
          </Box>
        )
      })}
    </Flex>
  )
}

export default function HomePage({ data }) {
  const nodes = data.allMdx.edges.map(edge => edge.node)
  const posts = nodes.map(node => {
    return { ...node.frontmatter, slug: node.fields.slug }
  })
  return (
    <div className="application">
      <SEO title={null} keywords={['spanish', 'fluent', 'exercises']} />
      <Header
        title="Break through your Spanish learning plateau"
        subtitle="Lessons, podcasts and interactive exercises to help you reach true fluency."
        includeSubscribeForm={true}
      />
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: 3,
          px: [2, 3, 3],
          minHeight: '100%',
        }}
      >
        <div sx={{ width: '100%', maxWidth: ['100%', '100%', '960px'] }}>
          <Text as="h2" sx={{ mb: 2 }}>
            Latest posts
          </Text>
          <RecentPosts posts={posts} placeholder={data.placeholder} />
          <Flex sx={{ justifyContent: 'flex-end', width: '100%', mt: 3 }}>
            <Link style={{ textDecoration: 'none' }} to="/blog">
              See all >
            </Link>
          </Flex>
          <Box sx={{ display: ['block', 'none'], mt: 5, width: '100%' }}>
            <EmailSubscribeForm />
          </Box>
          <Footer />
        </div>
      </Flex>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      limit: 3
      filter: { fields: { slug: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            date
            tags
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "post-image-placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
